/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
}

img {
  width: 100%;
  display: inline-block;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 2.75 !important;
  font-size: 12px;
  border: none !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #42b546 !important;
  color: white !important;
  /*border-radius: 5px;*/
}

.mat-button-toggle-group {
  border: 1px solid #42b546 !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  display: flex !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border: 1px solid #e1e3e5;
  border-bottom: none;

  #filter {
    border: none;
    width: 30%;
    padding: .75rem 1rem;
    border-radius: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/> </svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 20px;
    outline: none;
    ///* For Firefox */
    //-moz-padding-right: 30px;

    &::after {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    option {
      background-color: #fff;
      font-size: 16px;
    }
  }
}

.input {
  display: grid;
  grid-template-columns: 3fr auto;

  .icon {
    background-color: #42b546;
    width: 44px;
    display: grid;
    place-items: center;
    color: white;
    border-radius: 10px;
    z-index: 1;
  }

  .field {
    border: none;
    border-radius: 7px 0 0 7px;
    padding: .75rem 1.5rem;
    transform: translateX(5px);
    width: calc(100% + 5px);

    &:focus {
      outline: none;
    }
  }
}

.flex{
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon-container {
    display: grid;
    place-items: center;
    background-color: white;
    padding: .5rem;
    border-radius: 7px;
    color: #42b546;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }
}

table {
  border: 1px solid #e1e3e5 !important;
  box-shadow: none !important;

  .status {
    padding: .5em .75em;
    border-radius: 50px;
    text-align: center;
    margin: 0;
  }

  .pending {
    color: #ff8a1e;
    background-color: #fff0e2;
  }

  .completed {
    color: #18b9b9;
    background-color: #e5f5f5;
  }

  .cancelled {
    color: #fc1d1d;
    background-color: #f5e5e5;
  }
}

.mat-badge-content {
  background-color: #42b546 !important;
}

@media (max-width: 768px) {
  .search {
    width: 60% !important;
  }

  .mat-mdc-table[matTableResponsive] {
    .mat-mdc-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-mdc-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px;
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-mdc-row {
      height: auto;

      td.mat-mdc-cell:not(.mat-column-actions, .mat-column-expandedDetail, .mat-column-icon) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }
      }

      td.mat-mdc-cell:is(.mat-column-icon) {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
      }

      td.mat-mdc-cell:is(.mat-column-actions, .mat-column-expandedDetail) {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        border-bottom: 1rem solid #f5faf5;
      }

      &:last-of-type td:last-of-type {
        border: none;
      }
    }
  }

  .mat-mdc-card,
  .mat-expansion-panel {
    .mat-mdc-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
  // add buttons
  .button {
    width: 3rem !important;
    height: 3rem !important;
    bottom: 1.75rem !important;
    right: 0.4rem !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-mdc-fab .mat-button-wrapper{
    padding: 0 !important;
  }

}

@media (max-width: 475px) {
  .filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #filter {
      width: 250px !important;
      height: 44px;
    }
  }
}
